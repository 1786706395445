<template>
  <router-view name="main" />
</template>

<script>
// import { styled } from '@egoist/vue-emotion'

// const CalendarsMainStyled = styled('div')``

export default {
  components: {
    // CalendarsMainStyled,
  },
}
</script>
